import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  useColorMode,
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import AnimatedCountUpDecimal from "../../components/AnimatedCountUpDecimal"
import BannerBlepharo from "../../components/choc/BannerBlepharo"
import BannerHyaloron from "../../components/choc/BannerHyaloron"
import BannerVeoJadeklinik from "../../components/choc/BannerVeoJadeklinik"
import LogoHeadlineTeaser from "../../components/choc/LogoHeadlineTeaser"
import LogoHeadlineTeaserVeo from "../../components/choc/LogoHeadlineTeaserVEO"
import QuadTeaser from "../../components/choc/QuadTeaser"
import QuadTeaserFacelift from "../../components/choc/QuadTeaserFacelift"
import ScrollcompontentListelement from "../../components/choc/ScrollComponentListElement"
import ImgTextModule from "../../components/ImgTextModule"
import LayoutA from "../../components/layout/LayoutA"
import FadeInAnimation from "../../DefinitionComponents/Animation-Components/FadeInAnimation"
import MaskSlidInAnimation from "../../DefinitionComponents/Animation-Components/MaskSlideInAnimation"
import RouterLink from "../../DefinitionComponents/Utility/Routing/RouterLink"
import InViewTrigger from "../../DefinitionComponents/Utility/UX/InViewTrigger"

const Blank = () => {
  let { toggleColorMode, ColorMode, setColorMode } = useColorMode()
  useEffect(() => {
    setColorMode(colormodeValue)
  })
  const [viewportHeight, setViewportHeight] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  useEffect(() => {
    setViewportHeight(window.innerHeight)
    setPageHeight(document.body.clientHeight)
    // console.log('VIEWPORTHEIGHT: ' + window.innerHeight)
    // console.log('PAGEHEIGHT: ' + document.body.clientHeight)
  }, [])
  const [colormodeValue, changeColorModeValue] = useState("light")
  return (
    <LayoutA
      title={"Ästhetik: Liquid Facelift in Wilhelmshaven | VeoMed"}
      description={
        "Volumenausgleich zur Abmilderung der altersbedingten Fettatrophien im Gesicht. Schönheits-OP's, Behandlungen und Korrekturen: JadeKlinik in Wilhelmshaven."
      }
    >
      <ImgTextModule
        src={
          "343869_Waterdrops_Splashing_Body_Portrait_By_Ira_Belsky_Artlist_HD.mp4"
        }
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        rounded="none"
        h="75vh"
        mt={0}
        px={{ base: 4, md: 12, lg: 4, xl: 0 }}
        mediaOpacity={0.8}
        bgColor="black"
      >
        <Box p={{ base: 0, lg: "8" }}>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Heading
              color="brand.tan.ulight"
              fontWeight="bold"
              fontSize={{ base: "4xl", lg: "9xl" }}
            >
              Facelift:
            </Heading>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Heading
              color="brand.tan.ulight"
              fontWeight="bold"
              fontSize={{ base: "6xl", lg: "6xl" }}
            >
              Liquid Facelift
            </Heading>
          </FadeInAnimation>

          <Flex mt={0}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.5}
              duration={1.4}
              initialX={0}
              initialY={10}
            >
              <RouterLink
                isExternal={true}
                href="https://web.online-tis.de/start/M24437Yb6M"
              >
                <Button mt={4} variant="ghost" color="white">
                  Termine online
                </Button>
              </RouterLink>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={0.74}
              duration={1.4}
              initialX={0}
              initialY={10}
            >
              <RouterLink isExternal={false} link={"/kontakt/"}>
                <Button
                  ml={4}
                  mt={4}
                  variant="solid"
                  color="brand.violet.primary"
                  _hover={{
                    backgroundColor: "brand.violet.primary",
                    color: "white",
                  }}
                >
                  Kontakt
                </Button>
              </RouterLink>
            </FadeInAnimation>
          </Flex>
        </Box>
      </ImgTextModule>
      <LogoHeadlineTeaser
        py={24}
        headline="Was ist ein Liquid Facelift?"
        subheadline="
       Volumenausgleich zur Abmilderung der altersbedingten Fettatrophien im Gesicht.      
        "
      />
      <Container variant="layoutContainer" mt={0.5} py={12} mb={0.5} px={32}>
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Was?"
          text1="Was wird gemacht"
          text2="Je nach Bedarf und Wunsch, wird mit Eigenfett oder Hyaluron, das altersbedingte Volumendefizit aufgefüllt. Die Wirkung und Langlebigkeit des Verfahrens hängt dabei vom eingesetzten Mittel ab.
          "
        />

        <ScrollcompontentListelement
          sizevariant="wide"
          title="Und dann?"
          text1="Eine individuelle Behandlung"
          text2="
          Nach der Identifizierung und Markierung der gewünschten Gesichtsareale wird per Injektion Hyaluron mit einer hohen Standzeit eingebracht und einmassiert. Dieser Vorgang wird für jede gewünschte Partie wiederholt, bis das Ergebnis zufriedenstellend ist.

          "
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Vorteil"
          text1="Minimalinvasiv"
          text2="
          Bei Injektion von Eigenfett werden mit dem transplantierten Material körpereigene Stammzellen eingebracht, die zu einem nachhaltigen Verjüngungseffekt der Haut führen. Die klassische Hyaluronunterspritzung sorgt für eine erhöhte Feuchtigkeits-/ Wasseraufnahmekapazität der Haut. 
          "
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Besonderheit"
          text1="Individuell auf Sie ausgerichtet"
          text2="
          Die Wahl des Behandlungsverfahrens ist sehr individuell. Ob Eigenfett oder Hyaluron eingesetzt wird, hängt dabei von vielen Faktoren ab. Diese Entscheidung sollte gemeinsam in einem Beratungsgespräch getroffen werden. Übrigens: Hyaluron ist nicht gleich Hyaluron. Wir setzen ausschließlich freigegebene und hochqualitative Hyaluronpräparate ein, um eine höchstmögliche Behandlungsqualität zu gewährleisten.
          "
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Heilung"
          text1="Wie lange dauert es? "
          text2="
         Es ist mit geringen Behandlungsschwellungen zu rechnen. Gewöhnlich klingen die Schwellungen nach 3-7 Tagen ab. Hämatome können auftreten, sind aber überschminkbar.
          "
        />
      </Container>
      <Container variant="layoutContainer" minH="56vh">
        <ImgTextModule
          src={"32711.mp4"}
          mt={4}
          px={{ base: 4, md: 12, lg: 4, xl: 0 }}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          h="56vh"
          mediaOpacity={0.7}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <Flex mb={8}>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text
                  color="white"
                  fontWeight="normal"
                  fontSize={{ base: "2xl", lg: "2xl" }}
                >
                  Bereits ab :
                </Text>
              </FadeInAnimation>
              <InViewTrigger threshold={0.1} triggerOnce={false}>
                {inView => (
                  <AnimatedCountUpDecimal
                    inView={inView}
                    unit="€"
                    fontSizeUnit="lg"
                    count={1500}
                    fontSize={"6rem"}
                    duration={1}
                    decimal={false}
                    decimalDuration={2}
                    decimalCount={0}
                    unitSpacing={3}
                    numberColor={"white"}
                    textColor={"white"}
                    cellHeight={"6rem"}
                    letterSpacing={"-0.2rem"}
                  ></AnimatedCountUpDecimal>
                )}
              </InViewTrigger>
              <MaskSlidInAnimation threshold={1} duration={1}>
                <Heading
                  mr={32}
                  mt={2}
                  color={"white"}
                  fontSize={{ lg: "xl", md: "lg", base: "lg" }}
                  fontWeight="normal"
                  w={"100%"}
                  letterSpacing="tighter"
                >
                  Liquid Facelift
                </Heading>
              </MaskSlidInAnimation>
            </Flex>
            <Flex mt={0}>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text
                  color="white"
                  fontWeight="normal"
                  fontSize={{ base: "2xl", lg: "2xl" }}
                >
                  Bereits ab :
                </Text>
              </FadeInAnimation>
              <InViewTrigger threshold={0.1} triggerOnce={false}>
                {inView => (
                  <AnimatedCountUpDecimal
                    inView={inView}
                    unit="€"
                    fontSizeUnit="lg"
                    count={600}
                    fontSize={"6rem"}
                    duration={1}
                    decimal={false}
                    decimalDuration={2}
                    decimalCount={0}
                    unitSpacing={3}
                    numberColor={"white"}
                    textColor={"white"}
                    cellHeight={"6rem"}
                    letterSpacing={"-0.2rem"}
                  ></AnimatedCountUpDecimal>
                )}
              </InViewTrigger>
              <MaskSlidInAnimation threshold={1} duration={1}>
                <Heading
                  mr={32}
                  mt={2}
                  color={"white"}
                  fontSize={{ lg: "xl", md: "lg", base: "lg" }}
                  fontWeight="normal"
                  w={"100%"}
                  letterSpacing="tighter"
                >
                  Lippen- oder Nasenlabialunterspritzung
                </Heading>
              </MaskSlidInAnimation>
            </Flex>
            <Flex mt={0}>
              <FadeInAnimation
                threshold={0.4}
                delay={0.5}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <RouterLink
                  isExternal={true}
                  href="https://web.online-tis.de/start/M24437Yb6M"
                >
                  <Button mt={4} variant="ghost" color="white">
                    Termine online
                  </Button>
                </RouterLink>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={0.74}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <RouterLink isExternal={false} link={"/kontakt/"}>
                  <Button
                    ml={4}
                    mt={4}
                    variant="solid"
                    color="brand.violet.primary"
                    _hover={{
                      backgroundColor: "brand.violet.primary",
                      color: "white",
                    }}
                  >
                    Kontakt
                  </Button>
                </RouterLink>
              </FadeInAnimation>
            </Flex>
          </Box>
        </ImgTextModule>
      </Container>
      <Container variant="layoutContainer" minH="56vh">
        <ImgTextModule
          src={"35336.mp4"}
          mt={4}
          px={{ base: 4, md: 12, lg: 4, xl: 0 }}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          h="76vh"
          mediaOpacity={0.7}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Heading
                color="brand.tan.ulight"
                fontWeight="normal"
                fontSize={{ base: "5xl", lg: "6xl" }}
              >
                Wie geht es weiter?
              </Heading>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Heading
                color="brand.tan.ulight"
                fontWeight="normal"
                fontSize={{ base: "2xl", lg: "3xl" }}
              >
                Wir stehen gern zu einem Beratungs- <br />
                und Informationsgespräch bereit.
              </Heading>
              <Flex mt={0}>
                <FadeInAnimation
                  threshold={0.4}
                  delay={0.5}
                  duration={1.4}
                  initialX={0}
                  initialY={10}
                >
                  <RouterLink
                    isExternal={true}
                    href="https://web.online-tis.de/start/M24437Yb6M"
                  >
                    <Button mt={4} variant="ghost" color="white">
                      Termine online
                    </Button>
                  </RouterLink>
                </FadeInAnimation>

                <FadeInAnimation
                  threshold={0.4}
                  delay={0.74}
                  duration={1.4}
                  initialX={0}
                  initialY={10}
                >
                  <RouterLink isExternal={false} link={"/kontakt/"}>
                    <Button
                      ml={4}
                      mt={4}
                      variant="solid"
                      color="brand.violet.primary"
                      _hover={{
                        backgroundColor: "brand.violet.primary",
                        color: "white",
                      }}
                    >
                      Kontakt
                    </Button>
                  </RouterLink>
                </FadeInAnimation>
              </Flex>
            </FadeInAnimation>
          </Box>
        </ImgTextModule>
      </Container>
      <LogoHeadlineTeaserVeo
        headline="Ästhetik mit High-Tech"
        subheadline="Und Verantwortung"
      />{" "}
      <BannerHyaloron />
      <BannerBlepharo />
      <QuadTeaser />
      <Container variant="layoutContainer" mt={0.5} py={4} mb={0.5}>
        {" "}
        <BannerVeoJadeklinik />
      </Container>
    </LayoutA>
  )
}

export default Blank
